// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//
// export const environment = {
//   production: false,
//   baseUrl: 'http://localhost:3012/api/',
//   imagesUrl: 'http://localhost:3012/',
//   googleMapKey: 'AIzaSyB0GaervdIQIGZiuwQjzAarjqVDuRQzdvo',
// };

export const environment = {
  production: false,
  // baseUrl: 'http://192.168.1.128:3011/adminApp/',
  baseUrl: 'https://dev.vvip.com.sg:3011/api/',
  baseUrlAdmin: 'https://dev.vvip.com.sg:3011/adminApp/',
  // baseUrlAdmin: 'http://192.168.1.128:3011/adminApp/',
  imagesUrl: 'https://dev.vvip.com.sg:3011/',
  googleMapKey: 'AIzaSyB0GaervdIQIGZiuwQjzAarjqVDuRQzdvo',
};
